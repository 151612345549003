import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.ts";
// selectors 
    export   const  getControlPanelState  =   ( state :  RootState )   =>  state.controlPanel ;
    export   const  getUsers  =   createSelector ( getControlPanelState ,  ( state )   =>  state.users ) ;
    export   const  getDevices  =   createSelector ( getControlPanelState ,  ( state )   =>  state.devices ) ;
    export   const  getVolumes  =   createSelector ( getControlPanelState ,  ( state )   =>  state.volumes ) ;
    

    // get player timing by device id
    export   const  getPlayerTimingByDeviceId  =   ( state,deviceId :  string )   =>   createSelector ( getControlPanelState ,  ( state )   =>  state.playerTiming.find ( ( timing )   =>  timing.device_id  ===  deviceId ) ) ;


    