import { createSlice } from '@reduxjs/toolkit';


export const spaceUserOnlineSlice = createSlice({
  name: 'spaceUserOnlineList',
  initialState: {
    spaceUserOnlineList : []
  },
  reducers: {
    setSpaceUserOnline: (state, action) => {
        console.log(action.payload, "spaceUserOnlineListss");
        state.spaceUserOnlineList = action.payload
    }
  }
});

export const { spaceUserOnlineList, setSpaceUserOnline } = spaceUserOnlineSlice.actions;
export default spaceUserOnlineSlice.reducer;