import React from 'react'
import AutLayout from '../sections/AutLayout'



const Login = () => {
  return (
    <div>
   
  
      <AutLayout />
    </div>
  )
}

export default Login