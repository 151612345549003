// create slice for theme
import { createSlice } from '@reduxjs/toolkit';

export const infoSlice = createSlice({
  name: 'Info',
  initialState: {
    currentUserID:"",
    anchorEl:null,
    popoverOpen:false,
    callStatus:{},
    remoteStatus:{},
    orientation:"right"
  },
  reducers: {
    setCallStatus:(state,action)=>{
        state.callStatus=action.payload
    },
    setRemoteStatus: (state, action) => {
        state.remoteStatus = {...state.remoteStatus,[action.payload.id] :action.payload.status};
    },
    setPopoverOpen:(state,action)=>{
        state.popoverOpen = action.payload.open
        state.currentUserID = action.payload.id
        state.anchorEl = action.payload.anchorEl
        state.orientation = action.payload.orientation
    }
  }
});

export const { setCallStatus,setRemoteStatus,setPopoverOpen } = infoSlice.actions;
export default infoSlice.reducer;







