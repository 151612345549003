import { createSlice,current } from "@reduxjs/toolkit"
import { LAYOUT_TYPES } from "../conference/conferenceSlice"

// create spaceSlice
const spaceSlice = createSlice({
    name: 'space',
    initialState: {
        space: {},
        users:[],
        devices:[],
        positions:{},
        volumes:{},
        host:false,
        layout:LAYOUT_TYPES.DYNAMIC_LAYOUT_HORIZONTAL,
        audioMuted:false,
        videoMuted:false,

        // deviceid - unque device id for each device saved in cokkie

    }
    ,
    reducers: {
        setSpace: (state, action) => {
            state.space = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        }
        ,
        setDevices: (state, action) => {
            state.devices = action.payload
        }
        ,
        setPositions: (state, action) => {
            if(action.payload.position){
            state.positions[action.payload.id] = action.payload
        }
        else{
            delete state.positions[action.payload.id]
        }
        }
        ,
        setVolumes: (state, action) => {
            state.volumes = action.payload
        },
        setHost: (state, action) => {
            state.host = action.payload
        },
        setHostLeft: (state, action) => {
            let positions = current(state)
           console.log("host left",current(state));
         
           
        },
        setLayout: (state, action) => {
            state.layout = action.payload
        },
        setAudioMuted: (state, action) => {
            state.audioMuted = action.payload
        },
        setVideoMuted: (state, action) => {
            state.videoMuted = action.payload
        },  




    }
})

export const { setSpace, setUsers, setDevices, setPositions, setVolumes,setHost,setHostLeft } = spaceSlice.actions
export default spaceSlice.reducer