import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./state/store.ts";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { setContext } from "@apollo/client/link/context";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  createHttpLink,
  split,
} from "@apollo/client";

import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { Offline, Online } from "react-detect-offline";
import { OfflinePopup } from "./components/popup/offline.tsx";
import ComingSoon from "./components/maintenance/ComingSoon";

const token = localStorage.getObject("accessToken");

const httpLink = new createHttpLink({
  uri: "https://fox16-api-dev.kubes.meetmo.io/graphql/",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// console.log(`Bearer ${token}`)

const wsLink = new GraphQLWsLink(
  createClient({
    url: "https://fox16-api-dev.kubes.meetmo.io/ws/graphql/",
    connectionParams: {
      authToken: `Bearer ${token}`,
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Offline>
        <OfflinePopup />
      </Offline>
      <App />
      {/* <ComingSoon /> */}
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
