import React from 'react'

const Analytics = () => {
  return (
    <div style={{ backgroundColor:'#012A50',height:'96.7vh',display:'flex',justifyContent:'center',alignItems:'center' }}>
        <p style={{fontFamily:'URW DIN',color:'white'}}>
        Analytics
        </p>
        </div>
  )
}

export default Analytics