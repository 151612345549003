import { createSlice } from "@reduxjs/toolkit";


export interface CodecState {
  userId: string;
  supportedCodec: string[];
}

export interface codec {
  codecs: CodecState[];
}


export const codecSlice = createSlice({
  name: "codec",
  initialState: {
    codecs : [
    {
      userId: "",
      supportedCodec: [],
    },
  ]
  },

  reducers: {
    setCodec: (state, action) => {
      console.log("setCodec", action.payload);
      // push only if the new codec to the array
        if (state.codecs.length > 0) {
            let index = state.codecs.findIndex((codec) => codec.userId === action.payload.userId);
            if (index > -1) {
                state.codecs[index].supportedCodec = action.payload.supportedCodec;
            } else {
                state.codecs.push(action.payload);
            }
        } else {
            state.codecs.push(action.payload);
        }
        

    },
  },
});

export const { setCodec } = codecSlice.actions;

export default codecSlice.reducer;
