import { createSlice } from "@reduxjs/toolkit";


export interface spaceChat {
  id: number;
  message: string;
  message_type: "PB" | "PR";
  created: string;
  sender_id: number;
  sender_first_name: string;
  sender_last_name: string;
  sender_avatar: string;
}

interface SpaceChatState {
  spaceChats: spaceChat[];
  newChat: boolean;
  chatOpened: boolean;
}

const initialState: SpaceChatState = {
  spaceChats: [],
  newChat: false,
  chatOpened: false,
}

export const spaceChatSlice = createSlice({
  name: "spaceChats",
  initialState,
  reducers: {
    setSpaceChat: (state, action) => {
      // sort by created all the chats
      let chats = action.payload;
      chats.sort((a, b) => {
        return new Date(a.created).getTime() - new Date(b.created).getTime();
      }
      );
      state.spaceChats = chats;
    },
    deleteSpaceChat: (state, action) => {
      state.spaceChats = state.spaceChats.filter(
        (spaceChat) => spaceChat.id !== action.payload
      );
    },
    clearSpaceChat: (state) => {
      state.spaceChats = [];
    },
    addSpaceChat: (state, action) => {
      // sort by created all the chats
      let chats = state.spaceChats;
      chats.push(action.payload);
      chats.sort((a, b) => {
        return new Date(a.created).getTime() - new Date(b.created).getTime();
      }
      );
      state.spaceChats = chats;
    },
    newChatState: (state, action) => {
      console.log("newChatState", action.payload);
      
      state.newChat = action.payload;
    },
    setChatOpened: (state, action) => {
      console.log("setChatOpened", action.payload);
      
      state.chatOpened = action.payload;
    }
  }
});

export const { setSpaceChat, deleteSpaceChat, clearSpaceChat, addSpaceChat, newChatState, setChatOpened } = spaceChatSlice.actions;

export default spaceChatSlice.reducer;
