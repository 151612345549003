import { createSlice } from "@reduxjs/toolkit";
import { USER_TIERS } from "../conference/conferenceSlice";

export const STREAM_TYPES = {
  NORMAL: "normal",
  SCREEN: "screen",
  SHARING: "sharing",
};

export const LocalSlice = createSlice({
  name: "Local",
  initialState: {
    fromLoby: false,
    livekitInitialized: false,
    livekitRoom: null,
    userId: "",
    callStarted: false,
    audioTracks: [],
    videoTracks: [],
    currentAudioTrack: null,
    currentVideoTrack: null,
    streamType: STREAM_TYPES.NORMAL,
    shareUrl: "",
    videoEnabled: false,
    audioEnabled: false,
    videoMuted: localStorage.getObject("videoMuted") === "true" ? true : false,
    audioMuted: localStorage.getObject("audioMuted") === "true" ? true : false,
    userTier: USER_TIERS.TIER_1,
    audioDevices: [],
    videoDevices: [],
    audioOutputDevices: [],
    currentVideoDevice: {},
    currentAudioDevice: {},
    currentAudioOutputDevice: {},
    currentSpaceJwt: "",
    currentSpaceName: "",
    currentSubSpaceName: "",
    currentSubSpaceSlug: "",
    currentSubspace: {},
    showBottomMenu: false,
    linkSharing: false,
    screenSharing: false,
    initialAudioMuted:
      localStorage.getObject("audioMuted") === "true" ? true : false,
    videoPermission: false,
    audioPermission: false,
  },
  reducers: {
    setAudioPermission: (state, action) => {
      state.audioPermission = action.payload;
    },
    setVideoPermission: (state, action) => {
      state.videoPermission = action.payload;
    },
    setfromLoby: (state, action) => {
      state.fromLoby = true;
    },
    setScreenSharing: (state, action) => {
      state.screenSharing = action.payload;
    },
    setCurrentSpace: (state, action) => {
      if (action.payload.currentSubspace) {
        state.currentSubspace = action.payload.currentSubspace;
      }
      if (action.payload.currentSpaceJwt) {
        state.currentSpaceJwt = action.payload.currentSpaceJwt;
        localStorage.setObject(
          "currentSpaceJwt",
          action.payload.currentSpaceJwt
        );
      }
      if (action.payload.currentSpaceName) {
        state.currentSpaceName = action.payload.currentSpaceName;
        localStorage.setObject(
          "currentSpaceName",
          action.payload.currentSpaceName
        );
      }
      if (action.payload.currentSubSpaceName) {
        state.currentSubSpaceName = action.payload.currentSubSpaceName;
        localStorage.setObject(
          "currentSubSpaceName",
          action.payload.currentSubSpaceName
        );
      }
      if (action.payload.currentSubSpaceSlug) {
        state.currentSubSpaceSlug = action.payload.currentSubSpaceSlug;
        localStorage.setObject(
          "currentSubSpaceSlug",
          action.payload.currentSubSpaceSlug
        );
      }
    },
    setShowBottomMenu: (state, action) => {
      state.showBottomMenu = action.payload;
    },
    initializelivekit: (state) => {
      state.livekitInitialized = true;
    },
    setlivekitRoom: (state, action) => {
      state.livekitRoom = action.payload;
    },
    startCall: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.callStarted = true;
    },
    endCall: (state) => {
      state.callStarted = false;
    },

    setUserId: (state, action) => {
      state.userId = action.payload;
    },

    setcurrentSpaceJwt: (state, action) => {
      state.currentSpaceJwt = action.payload;
    },

    setcurrentSpaceName: (state, action) => {
      state.currentSpaceName = action.payload;
    },

    setStreamType: (state, action) => {
      state.streamType = action.payload;
    },

    setVideoEnabled: (state, action) => {
      state.videoEnabled = action.payload;
    },

    setAudioEnabled: (state, action) => {
      state.audioEnabled = action.payload;
    },

    setVideoMuted: (state, action) => {
      console.log(action.payload, "action.payload");
      state.videoMuted = action.payload;
    },

    setAudioMuted: (state, action) => {
      state.audioMuted = action.payload;
      if (state.currentAudioTrack) {
      }
    },

    setAudioTracks: (state, action) => {
      state.audioTracks = action.payload;
      state.currentAudioTrack = action.payload[0];
    },

    setVideoTracks: (state, action) => {
      state.videoTracks = action.payload;
      state.currentVideoTrack = action.payload[0];
    },
    setcurrentActiveVideoTrack: (state, action) => {
      state.currentVideoTrack = action.payload;
    },

    setCurrentVideoTrack: (state, action) => {
      console.log(action, "videoSwitching");
      if (action.payload == null) {
        state.videoMuted = true;

        try {
          window.room.sendCommandOnce("PARTICIPANT_PROPERTY", {
            value: JSON.stringify({
              property: "video",
              userId: state.userId,
              value: "muted",
            }),
          });

          window.room.getLocalVideoTracks().forEach((track) => track.mute());
        } catch (error) {
          console.log(error);
        }

        localStorage.setObject("videoMuted", true);

        try {
          state.currentVideoTrack.mute();
        } catch (error) {
          console.log(error);
        }
      } else {
        state.videoMuted = false;
        window.room.sendCommandOnce("PARTICIPANT_PROPERTY", {
          value: JSON.stringify({
            property: "video",
            userId: state.userId,
            value: "unmuted",
          }),
        });

        //for main event
        localStorage.setObject("videoMuted", false);

        state.videoTracks.forEach((elem) => {
          elem
            .unmute()
            .then(() => {
              console.log(elem.isMuted(), "videoSwitching");
            })
            .catch((err) => console.log(err, "videoSwitching"));

          try {
            state.currentVideoTrack.unmute();
          } catch (error) {
            console.log(error);
          }
        });
      }
    },

    setVideoSwitchTrack: (state, action) => {
      console.log(action, "videoSwitching3");
      //for lobby
      if (action.payload === null) {
        console.log("aa");
      } else {
        console.log(action.payload, "videoSwitching inside");
        // window.room.removeTrack(state.currentVideoTrack)
        // window.room.addTrack(action.payload);
        // state.currentVideoTrack = action.payload;
        if (
          action.payload.type == "video" &&
          action.payload.videoType != "desktop"
        ) {
          localStorage.setObject("camDeviceId", action.payload.getDeviceId());
          state.streamType = STREAM_TYPES.NORMAL;
          localStorage.setObject("streamType", STREAM_TYPES.NORMAL);

          state.videoMuted = false;
          window.room.sendCommandOnce("PARTICIPANT_PROPERTY", {
            value: JSON.stringify({
              property: "video",
              userId: state.userId,
              value: "unmuted",
            }),
          });
        } else if (
          action.payload.type == "video" &&
          action.payload.videoType == "desktop"
        ) {
          localStorage.setObject("streamType", STREAM_TYPES.SCREEN);

          state.streamType = STREAM_TYPES.SCREEN;
        }

        try {
          window.room.replaceTrack(state.currentVideoTrack, action.payload);
          state.currentVideoTrack = action.payload;
        } catch (error) {
          console.log(error);
          window.room.addTrack(action.payload);
        }
      }
    },

    setCurrentAudioTrack: (state, action) => {
      if (action.payload == null) {
        state.audioMuted = true;
        window.room.sendCommandOnce("PARTICIPANT_PROPERTY", {
          value: JSON.stringify({
            property: "audio",
            userId: state.userId,
            value: "muted",
          }),
        });
        window.room.sendCommandOnce("AudioMute", {
          value: JSON.stringify({ userId: state.userId, value: "muted" }),
        });

        state.currentAudioTrack?.mute();
        localStorage.setObject("audioMuted", true);
        window.room.getLocalAudioTrack()?.mute();
        state.currentAudioTrack = null;
      } else {
        localStorage.setObject("audioMuted", false);

        window.room.sendCommandOnce("AudioMute", {
          value: JSON.stringify({ userId: state.userId, value: "unmuted" }),
        });

        window.room.sendCommandOnce("PARTICIPANT_PROPERTY", {
          value: JSON.stringify({
            property: "audio",
            userId: state.userId,
            value: "unmuted",
          }),
        });

        if (
          window.room.getLocalAudioTrack()?.deviceId == action.payload.deviceId
        ) {
          window.room.getLocalAudioTrack()?.unmute();
          state.audioMuted = false;
        } else {
          try {
            state.currentAudioTrack = action.payload;
            state.audioMuted = false;
            window.room.replaceTrack(state.currentAudioTrack, action.payload);
          } catch (error) {
            console.log(error);
            state.audioMuted = false;
            state.currentAudioTrack = action.payload;
            window.room.addTrack(action.payload);
          }
        }
      }
    },

    setCurrentAudioTrackSwitch: (state, action) => {
      localStorage.setObject("micDeviceId", action.payload.getDeviceId());

      try {
        window.room.replaceTrack(state.currentAudioTrack, action.payload);
        state.currentAudioTrack = action.payload;
        state.audioMuted = false;
      } catch (error) {
        console.log(error);
        window.room.addTrack(action.payload);
        state.audioMuted = false;
        state.currentAudioTrack = action.payload;
      }
    },

    setUserTier: (state, action) => {
      state.userTier = action.payload;
    },

    setShareUrl: (state, action) => {
      console.log("setShareUrl", action.payload);
      state.shareUrl = action.payload;
    },

    // dispose audio and video tracks
    disposeTracks: (state) => {
      state.audioTracks.forEach((track) => track.dispose());
      state.videoTracks.forEach((track) => track.dispose());
      state.audioTracks = [];
      state.videoTracks = [];
    },

    // setAudioDevices

    setAudioDevices: (state, action) => {
      state.audioDevices = action.payload;
    },
    setCurrentAudioDevice: (state, action) => {
      state.currentAudioDevice = action.payload;
    },
    setVideoDevices: (state, action) => {
      state.videoDevices = action.payload;
    },
    setCurrentVideoDevice: (state, action) => {
      state.currentVideoDevice = action.payload;
    },

    setAudioOutputDevices: (state, action) => {
      state.audioOutputDevices = action.payload;
    },
    setCurrentAudioOutputDevice: (state, action) => {
      state.currentAudioOutputDevice = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAudioPermission,
  setVideoPermission,
  startCall,
  initializelivekit,
  endCall,
  setUserId,
  setStreamType,
  setVideoEnabled,
  setAudioEnabled,
  setVideoMuted,
  setAudioMuted,
  setAudioTracks,
  setVideoTracks,
  setUserTier,
  setShareUrl,
  setlivekitRoom,
  disposeTracks,
  setAudioDevices,
  setCurrentAudioDevice,
  setVideoDevices,
  setCurrentVideoDevice,
  setAudioOutputDevices,
  setCurrentAudioOutputDevice,
  setCurrentVideoTrack,
  setcurrentActiveVideoTrack,
  userId,
  setcurrentSpaceJwt,
  currentSpaceJwt,
  setcurrentSpaceName,
  currentSpaceName,
  setCurrentAudioTrack,
  setVideoSwitchTrack,
  setCurrentAudioTrackSwitch,
  setShowBottomMenu,
  setCurrentSpace,
  setScreenSharing,
  setfromLoby,
} = LocalSlice.actions;

export default LocalSlice.reducer;
