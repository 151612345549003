import React from 'react'
import RegisterLayout from '../sections/RegisterLayout'

const Register = () => {
  return (
    <div>
      <RegisterLayout/>
    </div>
  )
}

export default Register