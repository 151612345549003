// create slice for theme
import { createSlice } from '@reduxjs/toolkit';


export const permissionSlice = createSlice({
  name: "permissions",
  initialState: {
    switch_breakout_space: false,
    switch_video_on_off: false, //
    switch_audio_on_off: false,
    share_screen: false,
    raise_hand: false,
    change_layout: false,
    hang_up: false,
    show_three_dot_option: false,

    //admin-event
    add_space: false,
    edit_space: false,
    delete_space: false,

    //add-team
    add_team_members: false,
    delete_team_members: false,
    edit_team_members: false,
    // inside local 3 dots
    connectivity_test: false,
    performance_settings: false,
    share_video: false,
    share_audio: false,
    settings: false,
    control_panel: false,
    view_full_screen: false,
    view_shortcuts: false,

    //remote 3 dots
    show_three_dot_option_remote_users: false,
    control_volume_of_remote_users: false,
    move_to_sub_spaces: false,
    switch_role: false,
    view_as: false,
    request_unmute_audio: false,
    request_unmute_video: false,
    mute_video: false,
    mute_audio: false,
    start_system_readiness_check: false,
    show_spaces_menu: false,
    show_audio_and_video_buttons: false,
    kick_user: false,
    remove_user: false,

    drag_horizontal_layout: false,
    drag_vertical_layout: false,
    //loby
    show_online_people_loby: false,
    show_settings_button_loby: false,
  },
  reducers: {
    setPermissions: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
       
        state[key] = value;
      }
    },
  },
});

export const { setPermissions } = permissionSlice.actions;
export default permissionSlice.reducer;







