//create slice for media devices
import { createSlice } from "@reduxjs/toolkit";

export const mediaDeviceSlice = createSlice({
  name: "mediaDevice",
  initialState: {
    audioInputDeviceId: "",
    audioOutputDeviceId: "",
    videoInputDeviceId: localStorage.getObject("camDeviceId") || "",
  },
  reducers: {
    setAudioInputDeviceId: (state, action) => {
      state.audioInputDeviceId = action.payload;
    },
    setAudioOutputDeviceId: (state, action) => {
      state.audioOutputDeviceId = action.payload;
    },
    setVideoInputDeviceId: (state, action) => {
      state.videoInputDeviceId = action.payload;
    },
  },
});
export const {
  setAudioInputDeviceId,
  setAudioOutputDeviceId,
  setVideoInputDeviceId,
} = mediaDeviceSlice.actions;
export default mediaDeviceSlice.reducer;
