import React from 'react'

const ChatLoader = () => {
  return (
    <div className="chat">
      <div className="ball blue"></div>
      <div className="ball red"></div>
      <div className="ball yellow"></div>
      {/* <div className="ball green"></div> */}
    </div>
  );
}

export default ChatLoader