// livekit Slice
import {
  RemoteTrack,
} from 'livekit-client';
import { createSlice, current } from "@reduxjs/toolkit";


interface TrackPosition {
position: number;
trackId: string;
trackType: "P"| "V" | "S";
participantId: string;
default:boolean;


}
interface LivekitState {
  room: any;
  participants: any[];
  tracks: RemoteTrack[];
  audioTracks: any[];
  localAudioTracks: any[];
  activeSpeakers: any[];
  localParticipant: any;
  localTracks: any[];
  rerender: string;
  trackPosition: TrackPosition[];
}

const initialState: LivekitState = {
room: null,
participants: [],
tracks: [],
audioTracks: [],
localAudioTracks: [],
activeSpeakers: [],
localParticipant: null,
localTracks: [],
rerender:"",
trackPosition:[]
};

const livekitSlice = createSlice({
name: "livekit",
initialState,
reducers: {
  setTrackPosition(state, action) {
    console.log(action.payload,"reducer");
    if(action.payload && action.payload !== undefined){
    state.trackPosition = action.payload;
    }
  },
  addTrackPosition(state, action) {
    let trackType = action.payload.trackType;
    if(action.payload && action.payload !== undefined){
    let index = state.trackPosition.filter((item) => item.trackType === action.payload.trackType).length+1;
      // check if the track is already added
      let isTrackAdded = state.trackPosition.find((item)=>item.trackId === action.payload.trackId);
      if(!isTrackAdded){
       let data: TrackPosition = {
         trackId: action.payload.trackId,
         trackType: trackType,
         participantId: action.payload.participantId,
         default: action.payload.default,
         position: index,
       };
       
    state.trackPosition.push(data);
      }
    }
  },
  removeTrackPosition(state, action) {
    if(action.payload && action.payload !== undefined){
    let currentTrack = state.trackPosition.find((item)=>item.trackId === action.payload);
   let trackPosition  = state.trackPosition.reduce((acc:TrackPosition[],curr)=>{
    if(curr.trackType === currentTrack?.trackType){
      if(curr.trackId !== currentTrack.trackId){
        acc.push(curr);
      }
    }
      
      return acc;
    },[])
    //sort the trackPosition based on position
    trackPosition.sort((a,b)=>a.position-b.position);
    //update the position
    trackPosition.forEach((item,index)=>{
      item.position = index+1;
    }
    )
    let restPosition = state.trackPosition.filter((item)=>item.trackType !== currentTrack?.trackType);
    state.trackPosition = [...trackPosition,...restPosition];
    }
  },
  updateTrackPosition(state, action) {
    console.log(action.payload,"reducerUpdatetrackPosition 1");
    let trackPosition = [...current(state).trackPosition]
    console.log(trackPosition,"reducerUpdatetrackPosition 2");
    
    let currentTrack1 = trackPosition.find((item)=>item.trackId === action.payload.trackId);
    let prevPositionList  = trackPosition.reduce((acc:TrackPosition[],curr)=>{
      if(curr.trackType === currentTrack1?.trackType){
        if(curr.trackId !== currentTrack1.trackId){
          acc.push(curr);
        }
      }
      return acc;
     },[])

     console.log(currentTrack1,"reducerUpdatetrackPosition");
    //sort the trackPosition based on position
    prevPositionList.sort((a,b)=>a.position-b.position);
    //update the position
    prevPositionList = prevPositionList.map((item,index)=>{
      let newItem = {...item}
      newItem.position = index+1;

      return newItem;
    }
    )
    let changingPositionList = trackPosition.filter((item)=>item.trackType === action.payload.current_trackType);

    
    let restPositionList = trackPosition.filter((item)=>![action.payload.trackType,action.payload.current_trackType].includes(item.trackType) );
   console.log(prevPositionList,changingPositionList,restPositionList,"reducerUpdatetrackPosition");
   
    
    
    let currentTrack:TrackPosition = {
                                  position: changingPositionList.length+1,
                                  trackId: action.payload.trackId,
                                  trackType: action.payload.current_trackType
                                }
    console.log(currentTrack,"reducerUpdatetrackPosition");
    
    // currentTrack.position = prevPositionList.length+1;
    // add action.payload to restPosition
    changingPositionList.push(currentTrack);
    let newPos = [...prevPositionList,...changingPositionList,...restPositionList]
    console.log(changingPositionList,prevPositionList,"reducerUpdatetrackPosition",restPositionList);

    
    state.trackPosition = newPos;
    // state.rerender = `Viewer_${action.payload.trackId}_${action.payload.trackType}`
  },
  setRender(state, action) {
    state.rerender = action.payload;
  },
  setRoom(state, action) {
    state.room = action.payload;
  },
  setParticipants(state, action) {
    state.participants = action.payload;
  },
  setTracks(state, action) {
    state.tracks = action.payload;
  },
  addTrack(state, action) {
    state.tracks.push(action.payload);
  },
  setaudioTracks(state, action) {
    state.audioTracks.push(action.payload);
  },
  setlocalAudioTracks(state, action) {
    state.localAudioTracks.push(action.payload);
  },
  removeTrack(state, action) {
    state.tracks = state.tracks.filter(
      (track) => track.sid !== action.payload.sid
    );
  },
  setActiveSpeakers(state, action) {
    state.activeSpeakers = action.payload;
  },
  setLocalParticipant(state, action) {
    state.localParticipant = action.payload;
  },
  setLocalTracks(state, action) {
    state.localTracks = action.payload;
  },

},
});

export const {
  setTrackPosition,
  addTrackPosition,
  removeTrackPosition,
  updateTrackPosition,
  setRender,
  setRoom,
  setParticipants,
  setTracks,
  addTrack,
  setaudioTracks,
  removeTrack,
  setActiveSpeakers,
  setLocalParticipant,
  setLocalTracks,
  setlocalAudioTracks,
} = livekitSlice.actions;

export default livekitSlice.reducer;
