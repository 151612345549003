// create slice for user
import { createSlice } from '@reduxjs/toolkit';
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
    loggedIn: false,
    spaces:[]

  }
    ,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        setSpaces: (state, action) => {
          state.spaces = action.payload;
      },

    
    
    }
}
);
export const { setUser,setLoggedIn,setSpaces } = userSlice.actions;
export default userSlice.reducer;

