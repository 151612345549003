import { createSlice } from "@reduxjs/toolkit";

export interface colorPaletteState {
    theme_color_0: string;
    theme_color_1: string;
    theme_color_2: string;
    theme_color_3: string;
    theme_color_4: string;
    theme_color_5: string;
    theme_color_6: string;
    theme_color_7: string;
    theme_color_8: string;
    theme_color_9: string;
    theme_color_10: string;
    theme_color_11: string;
    theme_color_12: string;
    theme_color_13: string;
    theme_color_14: string;
    theme_color_15: string;
}

export interface colorPaletteLight {
    light_theme_color_0: string;
    light_theme_color_1: string;
    light_theme_color_2: string;
    light_theme_color_3: string;
    light_theme_color_4: string;
    light_theme_color_5: string;
    light_theme_color_6: string;
    light_theme_color_7: string;
    light_theme_color_8: string;
    light_theme_color_9: string;
    light_theme_color_10: string;
    light_theme_color_11: string;
    light_theme_color_12: string;
    light_theme_color_13: string;
    light_theme_color_14: string;
    light_theme_color_15: string;
}

export interface colorPalette {
    theme: string;
    dark_theme: colorPaletteState;
    light_theme: colorPaletteState;
    colorPickerColor: string;
}

interface ColorPaletteState {
    colorPalette: colorPalette;
}

const initialState: ColorPaletteState = {
    colorPalette: {
        theme: "dark",
        dark_theme: {
            theme_color_0: "rgba(1, 25, 52, 1)",
            theme_color_1: "rgba(1, 34, 67, 1)",
            theme_color_2: "rgba(1, 42, 80, 1)",
            theme_color_3: "rgba(0, 46, 86, 1)",
            theme_color_4: "rgba(20, 63, 99, 1)",
            theme_color_5: "rgba(38, 78, 110, 1)",
            theme_color_6: "rgba(55, 92, 120, 1)",
            theme_color_7: "rgba(75, 109, 133, 1)",
            theme_color_8: "rgba(93, 124, 144, 1)",
            theme_color_9: "rgba(110, 139, 155, 1)",
            theme_color_10: "rgba(136, 161, 171, 1)",
            theme_color_11: "rgba(165, 184, 192, 1)",
            theme_color_12: "rgba(225, 231, 234, 1)",
            theme_color_13: "rgba(255, 255, 255, 1)",
            theme_color_14: "rgba(230, 25, 89, 1)",
            theme_color_15: "rgba(0, 139, 205, 1)"
        },
        light_theme: {
            theme_color_0: "rgba(255, 255, 255, 1)",
            theme_color_1: "rgba(225, 231, 234, 1)",
            theme_color_2: "rgba(165, 184, 192, 1)",
            theme_color_3: "rgba(136, 161, 171, 1)",
            theme_color_4: "rgba(110, 139, 155, 1)",
            theme_color_5: "rgba(93, 124, 144, 1)",
            theme_color_6: "rgba(75, 109, 133, 1)",
            theme_color_7: "rgba(55, 92, 120, 1)",
            theme_color_8: "rgba(38, 78, 110, 1)",
            theme_color_9: "rgba(20, 63, 99, 1)",
            theme_color_10: "rgba(0, 46, 86, 1)",
            theme_color_11: "rgba(1, 42, 80, 1)",
            theme_color_12: "rgba(1, 34, 67, 1)",
            theme_color_13: "rgba(1, 25, 52, 1)",
            theme_color_14: "rgba(230, 25, 89, 1)",
            theme_color_15: "rgba(0, 139, 205, 1)"
        },
        colorPickerColor: "rgba(241, 112, 19, 1)"
    }
};

export const colorPaletteSlice = createSlice({
    name: "colorPalette",
    initialState,
    reducers: {
        setColorPalette: (state, action) => {
            state.colorPalette.theme = action.payload;
        },
        setIndividualColor: (state, action) => {
            console.log(action.payload, "action.payloadsetIndividualColor");

            state.colorPalette[action.payload.theme][action.payload.color] = action.payload.value;
        },
        setToInitialState: (state) => {
            state.colorPalette = initialState.colorPalette;
        },
        setDarkColorPalette: (state, action) => {
            console.log(action.payload, "action.payloadsetDarkColorPalette");

            state.colorPalette.dark_theme = {
                ...state.colorPalette.dark_theme,
                ...action.payload
            }
        },
        setLightColorPalette: (state, action) => {
            state.colorPalette.light_theme = {
                ...state.colorPalette.light_theme,
                ...action.payload
            }
        },
        setColorPickerColor: (state, action) => {
            state.colorPalette.colorPickerColor = action.payload;
        }

    }
});

export const { setColorPalette, setIndividualColor, setToInitialState, setDarkColorPalette, setLightColorPalette, setColorPickerColor } = colorPaletteSlice.actions;

export default colorPaletteSlice.reducer;