import { createSlice } from "@reduxjs/toolkit";

interface teamState {
  company_logo: string;
  users_count: number;
  company_name: string;
  email: string;
  is_organization_user: boolean;
  isDefault: boolean;
  is_active: boolean;
  default_organisation: boolean;
  is_my_team: boolean;
  first_name: string;
  last_name: string;
  slug: string;
  id: number;



}

const initialState: teamState = {
  company_logo: "",
  users_count: 1,
  company_name: "",
  email: "",
  is_organization_user: false,
  isDefault: false,
  is_active: false,
  default_organisation: false,
  is_my_team: false,
  first_name: "",
  last_name: "",
  slug: "",
  id: 0,
};

export const teamSlice = createSlice({
  name: "Team",
  initialState: {
    teams: [initialState],
    isMyTeam: {},
    defaultTeamSlug: "",
  },
  reducers
  : {
    createTeam: (state, action) => {
      if (state.teams[0].company_name == "") {
        state.teams[0] = action.payload;
      } else {
        let teamlist = [...state.teams]
        //find an object is exist in array of object and return boolean value
        let index = teamlist.findIndex(
          (team) => team.slug == action.payload.slug
        );

        if (index == -1) {
          
          teamlist.push(action.payload)
          state.teams = teamlist;
        }
      }
    },
    deleteTeam: (state, action) => {
      let teamlist = [...state.teams]
      //find the team inside teamlist and remove it
      try {
        let index = teamlist.findIndex(
          (team) => team.slug == action.payload.slug
        );
        console.log(index, "teamDeleteeee");

        teamlist.splice(index, 1);
        state.teams = teamlist;
      } catch (error) {
        console.log("aaa");
        
      }
      
    },
    editTeam: (state, action) => {
      let teamlist = [...state.teams]

      //update the team inside teamlist//find the team inside teamlist and remove it
      let index = teamlist.findIndex(
        (team) => team.slug == action.payload.slug
      );


      if (action.payload.company_logo) {
        teamlist[index].company_logo = action.payload.company_logo;
      }
      if (action.payload.company_name) {
        teamlist[index].company_name = action.payload.company_name;
      }
      // teamlist[index] = action.payload;

      state.teams = teamlist;
    },
    getIsMyTeam: (state, action) => {
      let teamlist = [...state.teams];
      //find a team inside teamlist and that team's is_my_team is true
      let index = teamlist.findIndex((team) => team.is_my_team === true);
      state.isMyTeam = teamlist[index];
      let defaultTeam = teamlist[index];

         localStorage.setObject("organization", defaultTeam.company_name);
         localStorage.setObject("organization_logo", "null");
         localStorage.setObject("team_slug", defaultTeam.slug);
         localStorage.setObject("organizationId", defaultTeam.id.toString());
         localStorage.setObject("organization_user_email", defaultTeam.email);
         localStorage.setObject(
           "is_organization_user",
           defaultTeam.is_organization_user.toString()
         );
    },
    defaultTeamSlug: (state, action) => {
      let teamlist = [...state.teams];
      //find a team inside teamlist and that team's is_my_team is true
      let index = teamlist.findIndex(
        (team) => team.default_organisation == true
      );
      state.defaultTeamSlug = teamlist[index].slug;
      console.log(teamlist[index].slug, "teamlist[index]");
    },
  },
});


export const { createTeam, deleteTeam, editTeam, getIsMyTeam, defaultTeamSlug } =
  teamSlice.actions;

export default teamSlice.reducer;



