import { createSlice } from "@reduxjs/toolkit";

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    firstName: "",
    lastName: "",
    email: "",
    avatar: "",
    time: "",
    timezone: "",
    userUpdated: {
      userId: "",
      type: "",
      value: "",
    },
  },
  reducers: {
    // set userUpdated
    setuserUpdated: (state, action) => {
      state.userUpdated = action.payload;
    },
    setuserProfile: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    setTimeZone: (state, action) => {
      state.timezone = action.payload;
    },
  },
});

export const { setuserProfile, setTimeZone, setuserUpdated } =
  userProfileSlice.actions;

export default userProfileSlice.reducer;
