// create slice for theme
import { createSlice } from '@reduxjs/toolkit';


export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    selectedPermission:{
      id:1,
      name:"participant",
        permissions:{
          global:{
            view_team_members:{
            can_add_team_members:true,
            can_remove_team_members:true,
            can_edit_team_members:true,
            },
            view_spaces:{
              can_add_spaces:true,
              can_remove_spaces:true,
              can_edit_spaces:true,
              can_share_spaces:true,
            },
           view_sub_spaces:{
            can_add_sub_spaces:true,
            can_remove_sub_spaces:true,
            can_edit_sub_spaces:true,
           },
            view_roles:{
              can_add_roles:true,
              can_remove_roles:true,
              can_edit_roles:true,
            },
           
            view_permissions:{
              can_add_permissions:true,
              can_remove_permissions:true,
              can_edit_permissions:true,
            },
            view_users:{
              can_add_users:true,
              can_remove_users:true,
              can_edit_users:true,
            can_invite_users:true,

            },
           view_workflows:{
            can_add_workflows:true,
            can_remove_workflows:true,
            can_edit_workflows:true,
           },
         
            
          },
            space:{ 

             

              can_switch_breakout_space:true,
              can_switch_video_on_off:true,
              can_switch_audio_on_off:true,
              share_screen:true,
              raise_hand:true,
              change_layout:true,
              hang_up:true,
              show_three_dot_option:true,
              // inside local 3 dots
              connectivity_test:true,
              performance_settings:true,
              share_video:true,
              share_audio:true,
              settings:true,
              control_panel:true,
              view_full_screen:true,
              view_shortcuts:true,


               //remote 3 dots
              show_three_dot_option_remote_users : true,
              control_volume_of_remote_users: true,
              move_to_sub_spaces:true,
              switch_role:true,
              view_as:true,
              request_unmute_audio:true,
              request_unmute_video:true,
              mute_video:true,
              mute_audio:true,
              start_system_readiness_check:true,
              show_spaces_menu:true,
              show_audio_and_video_buttons:true,
              kick_user:true,
              remove_user:true,
    
              drag_horizontal_layout:true,
              drag_vertical_layout:true,


           },
           lobby:{},
           video_quality:{
          
            streaming_quality:{
              1:{
                id:1,
                resolution:"180p",
                bitrate:"1 Mbps"
              },
              2:{
                id:2,
                resolution:"720p",
                bitrate:"2 Mbps"
              }
            },
            viewing_quality:{
              1:{
                id:1,
                resolution:"180p",
               
              },
              2:{
                id:2,
                resolution:"480p",
                
              }
            },
          },
      }
     

    },
    permissions:{
      participant:{
        id:1,
      name:"participant",
      default:true,
      permissions:{
            event:{ 
              can_switch_breakout_space:true,
              can_switch_video_on_off:true,
              can_switch_audio_on_off:true,
              share_screen:true,
              raise_hand:true,
              change_layout:true,
              hang_up:true,
              show_three_dot_option:true,
              // inside local 3 dots
              connectivity_test:true,
              performance_settings:true,
              share_video:true,
              share_audio:true,
              settings:true,
              control_panel:true,
              view_full_screen:true,
              view_shortcuts:true,


               //remote 3 dots
              show_three_dot_option_remote_users : true,
              control_volume_of_remote_users: true,
              move_to_sub_spaces:true,
              switch_role:true,
              view_as:true,
              request_unmute_audio:true,
              request_unmute_video:true,
              mute_video:true,
              mute_audio:true,
              start_system_readiness_check:true,
              show_spaces_menu:true,
              show_audio_and_video_buttons:true,
              kick_user:true,
              remove_user:true,
    
              drag_horizontal_layout:true,
              drag_vertical_layout:true,


           },
           lobby:{},
           admin:{
            
           }
      }
      },
      guest:{
        id:2,
      name:"guest",
      default:true,
      permissions:{
            event:{ 
              can_switch_breakout_space:true,
              can_switch_video_on_off:true,
              can_switch_audio_on_off:true,
              share_screen:true,
              raise_hand:true,
              change_layout:true,
              hang_up:true,
              show_three_dot_option:true,
              // inside local 3 dots
              connectivity_test:true,
              performance_settings:true,
              share_video:true,
              share_audio:true,
              settings:true,
              control_panel:true,
              view_full_screen:true,
              view_shortcuts:true,


               //remote 3 dots
              show_three_dot_option_remote_users : true,
              control_volume_of_remote_users: true,
              move_to_sub_spaces:true,
              switch_role:true,
              view_as:true,
              request_unmute_audio:true,
              request_unmute_video:true,
              mute_video:true,
              mute_audio:true,
              start_system_readiness_check:true,
              show_spaces_menu:true,
              show_audio_and_video_buttons:true,
              kick_user:true,
              remove_user:true,
    
              drag_horizontal_layout:true,
              drag_vertical_layout:true,


           },
           lobby:{},
           admin:{
            
           }
      }
      },
      viewer:{
        id:3,
      name:"viewer",
      default:true,
      permissions:{
            event:{ 
              can_switch_breakout_space:true,
              can_switch_video_on_off:true,
              can_switch_audio_on_off:true,
              share_screen:true,
              raise_hand:true,
              change_layout:true,
              hang_up:true,
              show_three_dot_option:true,
              // inside local 3 dots
              connectivity_test:true,
              performance_settings:true,
              share_video:true,
              share_audio:true,
              settings:true,
              control_panel:true,
              view_full_screen:true,
              view_shortcuts:true,


               //remote 3 dots
              show_three_dot_option_remote_users : true,
              control_volume_of_remote_users: true,
              move_to_sub_spaces:true,
              switch_role:true,
              view_as:true,
              request_unmute_audio:true,
              request_unmute_video:true,
              mute_video:true,
              mute_audio:true,
              start_system_readiness_check:true,
              show_spaces_menu:true,
              show_audio_and_video_buttons:true,
              kick_user:true,
              remove_user:true,
    
              drag_horizontal_layout:true,
              drag_vertical_layout:true,


           },
           lobby:{},
           admin:{
            
           }
      }
      }
   
    },
    workflows:{},
    departments:{},
    roles:{},
 
  },
  reducers: {
    setPermissionInSelectedPermission: (state, action) => {
        state.selectedPermission.permissions[action.payload.permissionType][action.payload.permission] =action.payload.value 
    },
    addStreamingQuality: (state, action) => {

    },
    removeStreamingQuality: (state, action) => {

    }

  }
});

export const { setPermissionInSelectedPermission } = adminSlice.actions;
export default adminSlice.reducer;







