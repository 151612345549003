import { AxiosLocal } from "../utilities/axiosUtils.ts"
import { getTeamSlugFromUrl } from "../utilities/common.js"

// impliment every realtime properties of user
type User = {
    id: string
    livekitId: string
    position?: number
    userType?: string
}

let users:Array<User> = []

let addUser = (id:string, livekitId:string) => {
    users.push({id, livekitId})
}

let removeUser = (id:string) => {
    users = users.filter(user => user.id !== id)
}

let getUser = (id:string) => {
    return users.find(user => user.id === id)
}

let getUserBylivekitId = (livekitId:string) => {
    return users.find(user => user.livekitId === livekitId)
}

let getAllUsers = () => {
    // check if id is not undefined
   let  new_users =  users.map(user => {
        if (user.id === undefined){
            // @ts-ignore
            user.id = window.room?.getParticipantById(user.livekitId )?._properties?.id
          
    }

   
        return user
})
users = new_users
return new_users

}
let userProperties = (userId)=>{
    // check userId is in the room
    let user = window.room.getParticipantById(userId)
    if (user){
        return {
            "firstName": user.getProperty("firstName"),
            "lastName": user.getProperty("lastName"),
            "avatar": user.getProperty("avatar"),
            "volume": user.getProperty("volume"),
            "userId": user.getProperty("userId"),
            "video": user.getProperty("video"),
            "audio": user.getProperty("audio"),
            "desktop": user.getProperty("desktop"),
            "id": user.getProperty("id"),
            "priority": user.getProperty("priority"),
            "guestUser": user.getProperty("guestUser"),
            "role": user.getProperty("role"),
            
        }
    }
    else{
        return {
            "firstName": window.room.getLocalParticipantProperty("firstName"),
            "lastName": window.room.getLocalParticipantProperty("lastName"),
            "avatar": window.room.getLocalParticipantProperty("avatar"),
            "volume": window.room.getLocalParticipantProperty("volume"),
            "userId": window.room.getLocalParticipantProperty("userId"),
            "video": window.room.getLocalParticipantProperty("video"),
            "audio": window.room.getLocalParticipantProperty("audio"),
            "desktop": window.room.getLocalParticipantProperty("desktop"),
            "id": window.room.getLocalParticipantProperty("id"),
            "priority": window.room.getLocalParticipantProperty("priority"),
            "guestUser": window.room.getLocalParticipantProperty("guestUser"),
            "role": window.room.getLocalParticipantProperty("role"),
            
        }
    }

   
}

let allParticipants =()=> window.room.getParticipants().map(user => {
    return userProperties(user.getId())
}).concat([userProperties(window.room.myUserId())])


// localStorage.getObject("currentSubSpaceSlug")
// room config
  let team_slug = getTeamSlugFromUrl("space");

const getRoomConfig = async(currentSubSpaceSlug) => {
   let roomConfig = await AxiosLocal.get(`space/config/${currentSubSpaceSlug}/?team_slug=${team_slug}`)
   
   
  
        try {
        console.log(roomConfig.data.data.space_config,"subroom/edit/");
          return roomConfig.data.data.space_config
        } catch (error) {
            console.log(error);
            
          return {}
        }
    

}

// get room config filter by property
const getRoomConfigByProperty = async(currentSubSpaceSlug, property) => {
    let roomConfig = await getRoomConfig(currentSubSpaceSlug)
    return roomConfig[property]
}

// update room config
let updateRoomConfig = async(config) => {
    let currentRoomConfig = await getRoomConfig(localStorage.getObject("currentSubSpaceSlug"))
    let space_config = {
        ...currentRoomConfig,
       ...config
    }
    let roomConfig = await AxiosLocal.post(`space/config/${localStorage.getObject("currentSubSpaceSlug")}/?team_slug=${team_slug}`,{
        space_config
    })
    console.log(roomConfig.data, 'subroom details')
}

// update room config for a property
let updateRoomConfigByProperty = async(property, value) => {
    let currentRoomConfig = await getRoomConfig(localStorage.getObject("currentSubSpaceSlug"))
    let roomConfig = await AxiosLocal.post(`subroom/edit/${localStorage.getObject("currentSubSpaceSlug")}`,{
        ...currentRoomConfig,
        [property]: value
    })
    console.log(roomConfig.data, 'subroom details')
}

// get user space config
const getUserSpaceConfig = async() => {
   let res = await AxiosLocal.get(`user/space/config/${localStorage.getObject('currentSubSpaceSlug')}/${localStorage.getObject('id')}/?team_slug=${team_slug}`)
        let data ={}
        console.log(res.data,"res.data");
        if(res.data !== ""){
            data = res.data.data.space_config
            if(data.hasOwnProperty("participantPositions")){
                let newParticipantPositions = {}
                for (const [key, value] of Object.entries(data.participantPositions)) {
                    if(key !== "self"){
                    newParticipantPositions[key] = value
                    }
                    else{
                        newParticipantPositions[window.room?.myUserId()] = value
                    }
                }

                data.participantPositions = newParticipantPositions
            }
        }
        return data
      

}
// function to update user space config
let updateUserSpaceConfig = ( config) => {
    AxiosLocal.get(`user/space/config/${localStorage.getObject('currentSubSpaceSlug')}/${localStorage.getObject('id')}/?team_slug=${team_slug}`).then((res)=>{
        let data ={}
        console.log(res.data,"res.data");
        if(res.data !== ""){
            data = res.data.data.space_config
        }
       let space_config = {
            ...data,
           ...config
        }
        AxiosLocal.post("user/space/config/",{
            "subroom_slug":localStorage.getObject('currentSubSpaceSlug'),
            "user_id":localStorage.getObject('id'),
            space_config,
            team_slug: team_slug
        }).then((res)=>{
            console.log(res.data,"res.data");
        }
        )
      
    })
}
export  {
    addUser,
    removeUser,
    getUser,
    getUserBylivekitId,
    getAllUsers,
    getRoomConfig,
    updateRoomConfig,
    getRoomConfigByProperty,
    updateRoomConfigByProperty,
    allParticipants,
    updateUserSpaceConfig,
    getUserSpaceConfig
}