import { configureStore } from '@reduxjs/toolkit'
import  local from './local/localSlice'
import theme from './theme/themeSlice'
import conference from './conference/conferenceSlice'
import user from './user/userSlice'
import permissions from './permissions/permissionSlice.js'
import info from './info/infoSlice'
import admin from './admin/adminSlice'
import loggedinUsers from './usersLoggedin/usersLoggedinSlice'
import spaceUserOnlineList from './spaceUserOnline/spaceUserOnlineSlice'
//@ts-ignore
import space from './space/spaceSlice.ts'
import userProfile from "./userProfile/userProfileSlice";
//@ts-ignore
import controlpanel from './controlpannel/slice.ts'
//@ts-ignore
import spaceChats from './spaceChat/spaceChatSlice.ts'
//@ts-ignore
import colorPalette from './colorPalette/colorPaletteSlice.ts'
//@ts-ignore
import livekit from './livekit/slice.ts'

import mediaDevice from './mediaDevices/mediaDeviceSlice'

//@ts-ignore
import assignedSpaces from './assignedSpaces/assignedSpacesSlice.ts'
//@ts-ignore
import codec from './codec/codecSlice.ts'
//@ts-ignore
import Team from './team/teamSlice.ts'

 const store = configureStore({
   reducer: {
     local,
     conference,
     theme,
     user,
     info,
     permissions,
     admin,
     loggedinUsers,
     spaceUserOnlineList,
     space,
     controlpanel,
     userProfile,
     spaceChats,
     colorPalette,
     livekit,
     mediaDevice,
     assignedSpaces,
     codec,
     Team,
   },
   middleware: (getDefaultMiddleware) =>
     getDefaultMiddleware({
       serializableCheck: false,
     }),
 });

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch