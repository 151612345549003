import { createSlice } from "@reduxjs/toolkit";

interface subSpaceType {
    id: number;
    name: string;
    room_image: string;
    room_slug: string;
    slug: string;
    auto_start_space: boolean;
    is_lobby: boolean;
    is_master: boolean;
}

export interface spaceType {
    id: number;
    name: string;
    main_logo: string;
    menu_logo: string;
    slug: string;
    background_image?: string;
    subrooms?: subSpaceType[];
}

interface SpaceState {
    selectedSpaces: spaceType[];
    currentSpace: spaceType;
    currentSubSpace: subSpaceType;
    nonselectedSpaces: spaceType[];
}

const initialState: SpaceState = {
    selectedSpaces: [],
    currentSpace: {},
    currentSubSpace: {},
    nonselectedSpaces: [],
};

export const assignedSpacesSlice = createSlice({
    name: "assignedSpaces",
    initialState,
    reducers: {
        setSelectedSpaces: (state, action) => {
            state.selectedSpaces = action.payload;
        },
        setNonselectedSpaces: (state, action) => {
            console.log("setNonselectedSpaces", action.payload);
            
            state.nonselectedSpaces = [...action.payload];
        },
        setCurrentSpaceData: (state, action) => {
            state.currentSpace = action.payload;
        },
        setCurrentSubSpaceData: (state, action) => {
            state.currentSubSpace = action.payload;
        },
        updateNonselectedSpaceData: (state, action) => {
            console.log("updateNonselectedSpaceData", action.payload);
            
            let spaceData = action.payload;
            let index = state.nonselectedSpaces.findIndex(
                (space) => space.id === action.payload.id
            );
            state.nonselectedSpaces[index].name = spaceData.name;
        },
        updateSelectedSpaceData: (state, action) => {
            let spaceData = action.payload;
            state.selectedSpaces[0].name = spaceData.name;
        },
        updateCurrentSpaceData: (state, action) => {
            let spaceData = action.payload;
            state.currentSpace.name = spaceData.name;
        },
        updateCurrentSubSpaceData: (state, action) => {
            let subSpace = action.payload;
            state.currentSubSpace = subSpace;
        },
        addSubSpaceToNonSelectedSpaces: (state, action) => {
            console.log("addSubSpaceToNonSelectedSpaces", action.payload);
            
            let payloadData = action.payload;
            // find subroom index in non selected spaces array and update it handeling the case of undefined
            let index = state.nonselectedSpaces.findIndex(
                (space) => space.slug === payloadData.spaceSlug
            );
            console.log("addSubSpaceToNonSelectedSpaces", index);
            
            if (index !== -1) {
                // if there is no subrooms array create it and push the subroom
                if (!state.nonselectedSpaces[index].subrooms) {
                    state.nonselectedSpaces[index].subrooms = [];
                }
                //@ts-ignore
                state.nonselectedSpaces[index].subrooms = [ ...payloadData.subrooms ];
            }
        },
        addSubSpaceToSelectedSpaces: (state, action) => {
            console.log("addSubSpaceToSelectedSpaces", action.payload);

            let payloadData = action.payload;
            // find subroom index in non selected spaces array and update it handeling the case of undefined
            let index = state.selectedSpaces.findIndex(
                (space) => space.slug === payloadData.spaceSlug
            );
            console.log("addSubSpaceToSelectedSpaces", index);
                
            if (index !== -1) {
                // if there is no subrooms array create it and push the subroom
                if (!state.selectedSpaces[index].subrooms) {
                    state.selectedSpaces[index].subrooms = [];
                }
                //@ts-ignore
                state.selectedSpaces[index].subrooms = [ ...payloadData.subrooms ];
            }
        },
        updateNonSelecteSubSpaceData: (state, action) => {
            console.log("updateNonSelecteSubSpaceData", action.payload);
            let subSpaceData = action.payload;
            // find subroom index in non selected spaces array and update it handeling the case of undefined
            let index = state.nonselectedSpaces.findIndex(
                (space) => space.id === subSpaceData.main_space_id
            );
            if (index !== -1) {
                //@ts-ignore
                let subRoomIndex = state.nonselectedSpaces[index].subrooms.findIndex(
                    (subRoom) => subRoom.id === subSpaceData.id
                );
                //@ts-ignore
                state.nonselectedSpaces[index].subrooms[subRoomIndex].name = subSpaceData.name;
            }
        },
        updateSelecteSubSpaceData: (state, action) => {
            let subSpaceData = action.payload;
            //@ts-ignore
            let subRoomIndex = state.selectedSpaces[0].subrooms.findIndex(
                (subRoom) => subRoom.id === subSpaceData.id
            );
            //@ts-ignore
            state.selectedSpaces[0].subrooms[subRoomIndex].name = subSpaceData.name;
        }
    },
});

export const {
    setSelectedSpaces,
    setNonselectedSpaces,
    setCurrentSpaceData,
    setCurrentSubSpaceData,
    updateCurrentSubSpaceData,
    updateNonselectedSpaceData,
    updateSelectedSpaceData,
    updateCurrentSpaceData,
    updateNonSelecteSubSpaceData,
    updateSelecteSubSpaceData,
    addSubSpaceToNonSelectedSpaces,
    addSubSpaceToSelectedSpaces
} = assignedSpacesSlice.actions;

export default assignedSpacesSlice.reducer;