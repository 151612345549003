import { createSlice } from '@reduxjs/toolkit';


export const usersLoggedinSlice = createSlice({
  name: 'loggedinUsers',
  initialState: {
    loggedinUsers : []
  },
  reducers: {
    setLoggedinUsers: (state, action) => {
        state.loggedinUsers = action.payload
    },
    setAddLoggedinUser: (state, action) => {
      let userId = action.payload.user_id
      let loggedinUserList = state.loggedinUsers
      if(action.payload.type == "login"){
        if(!loggedinUserList.includes(userId)){
          loggedinUserList.push(userId)
        }
      }
      else{
        const index = [...loggedinUserList].indexOf(userId);
        loggedinUserList = loggedinUserList.filter(function(item) {
            return item !== userId
        })
      }
      state.loggedinUsers = [...loggedinUserList]
    }
  }
});

export const { loggedinUsers, setLoggedinUsers, setAddLoggedinUser } = usersLoggedinSlice.actions;
export default usersLoggedinSlice.reducer;