// controlPannelSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { tableData } from '../../components/ControlPanel/index.tsx'




interface Volume {
    device_id?:string
    user_id: string
    user_name: string
    volume: number
    type:"USER"|"DEVICE"
    custom_id:string
}

interface PlayerTiming {
    currentTime: number
    duration: number
    device_id: string
    user_id: string
}


interface ControlPannelState {
    users: any[]
    devices: tableData[]
    volumes: Volume[]
    onlineUsers: any[]
    UserCounts: {primary: number, secondary: number,hidden: number,online: number,offline: number,handraise: number,guest: number}
    playerTiming: PlayerTiming[]
}



const initialState: ControlPannelState = {
    users: [],
    devices: [],
    volumes: [],
    onlineUsers: [],
    UserCounts: {primary: 0, secondary: 0,hidden: 0,online: 0,offline: 0,handraise: 0,guest: 0},
    playerTiming:[]

}
export const controlPannelSlice = createSlice({
    name: 'controlPannel',
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<any[]>) => {
            state.users = action.payload
        },
        setDevices: (state, action: PayloadAction<any[]>) => {
            state.devices = action.payload
        },
        addDevice: (state, action: PayloadAction<tableData>) => {

            state.devices.push(action.payload)
            
           try {
            //  window.eventChanel.publish({event:"askForDevices"})
           } catch (error) {
            console.log("error",error);
            
           }

        },
        removeDevice: (state, action: PayloadAction<string>) => {
          
            state.devices = state.devices.filter((device) => device.id !== action.payload)
            // window.eventChanel.publish({event:"askForDevices"})
            
        },
        // checkDirectorLeftControlPannel: (state, action: PayloadAction<string>) => {
        //     //filter devices with user_id from action.payload and update state.devices
        //     state.devices = state.devices.filter((device) => device.user_id !== action.payload)
        // },
        setVolumes: (state, action: PayloadAction<any[]>) => {
            state.volumes = action.payload
        },
        addVolume: (state, action: PayloadAction<Volume>) => {
            console.log("addVolume",action.payload);
            
            // if volume is already present then update it otherwise add it
            let index = state.volumes.findIndex(
              (volume) => volume.device_id === action.payload.device_id
            );
            if (index !== -1) {
                state.volumes[index] = action.payload
            } else {
            state.volumes.push(action.payload)
            }
        },
        removeVolume: (state, action: PayloadAction<string>) => {
            state.volumes = state.volumes.filter((volume) => volume.device_id !== action.payload)
        },

        setOnlineUsers: (state, action: PayloadAction<any[]>) => {
            state.onlineUsers = action.payload
        },
        addOnlineUser: (state, action: PayloadAction<any>) => {
            state.onlineUsers.push(action.payload)
        },
        removeOnlineUser: (state, action: PayloadAction<string>) => {
            state.onlineUsers = state.onlineUsers.filter((user) => user.user_id !== action.payload)
        },
        setOnlineUserCounts: (state, action: PayloadAction<{primary: number, secondary: number,hidden: number,online: number,offline: number,handraise: number,guest: number}>) => {
            state.UserCounts = action.payload
        },
        setPlayerTiming: (state, action: PayloadAction<PlayerTiming>) => {
            state.playerTiming.push(action.payload)
        },
        addPlayerTiming: (state, action: PayloadAction<PlayerTiming>) => {
            // if playerTiming is already present then update it otherwise add it
            let index = state.playerTiming.findIndex((playerTiming) => playerTiming.device_id === action.payload.device_id)
            if (index !== -1) {
                state.playerTiming[index] = action.payload
            } else {
                state.playerTiming.push(action.payload)
            }
         
        },

        removePlayerTiming: (state, action: PayloadAction<string>) => {
            state.playerTiming = state.playerTiming.filter((playerTiming) => playerTiming.device_id !== action.payload)
        }



    }
})
export const { setUsers, setDevices, addDevice, removeDevice, setVolumes, addVolume, removeVolume, setOnlineUsers, addOnlineUser, removeOnlineUser, setOnlineUserCounts, setPlayerTiming, addPlayerTiming, removePlayerTiming } = controlPannelSlice.actions
export default controlPannelSlice.reducer