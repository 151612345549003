// create slice for theme
import { createSlice } from "@reduxjs/toolkit";
import { WEBSITE_PATH } from "../../utilities/websiteUrls.ts";
// let styles = require("../../styles.json");
/**
 * Extending the Local Storage Object to allow saving of objects.
 *
 * @param  int|string   key     object key
 * @param  int|string   value   object value
 * @return bool                 true|false
 */

const keywords = [
  "spaces",
  "admin",
  "register",
  "404",
  "waiting",
  "track",
  "auth",
  "test_keyboard_shortcuts",
  "permission",
  "invitation",
];



Storage.prototype.setObject = function (key, value) {
  const path = window.location.pathname;
  if (path === "/") {

  }
  else if (!keywords.some(substring => path.includes(substring))) {
    console.log(
      'hgvgvhchc'
    );

    key = window.location.pathname.split("/").pop() + "_" + key;

  }
  console.log(key);
  this.setItem(key, JSON.stringify(value));
  const event = new Event("storage");
  dispatchEvent(event);
};

/**
 * Extending the Local Storage Object to allow returning of saved objects.
 *
 * @param  int|string   key     object key
 * @return int|string           value
 */
Storage.prototype.getObject = function (key) {
  const path = window.location.pathname;
  let new_key = key;
  if (path === "/") {

  }
  else if (!keywords.some(substring => path.includes(substring))) {
    new_key = window.location.pathname.split("/").pop() + "_" + key;

  }

  var value = this.getItem(new_key);

  if (value == null) {
    value = this.getItem(key);

  }
  let data
  try {
    console.log(value && typeof JSON.parse(value), value);
    if (value && typeof JSON.parse(value) == "boolean") {
      data = value
    }
    else {
      data = value && JSON.parse(value);
    }
  }
  catch (error) {
    data = value
  }
  return data
};
let color_pellets = require("../../meetmo_color_palettes.json");
if (WEBSITE_PATH.includes("events.fox")) {
  color_pellets = require("../../fox_color_palettes.json");
} else {
  color_pellets = require("../../meetmo_color_palettes.json");
}
// if (localStorage.getObject("domain") == "fox") {
//   color_pellets = require("../../fox_color_palettes.json");
// } else {
//   color_pellets = require("../../meetmo_color_palettes.json");
// }

let theme = localStorage.getObject("theme");
if (!theme) {
  theme = "dark";
  localStorage.setObject("theme", "dark");
}

let styles = {
  dark: {
    bg_color_0: color_pellets.dark.dark_theme_color_0,
    bg_color_1: color_pellets.dark.dark_theme_color_1,
    bg_color_2: color_pellets.dark.dark_theme_color_2,
    bg_color_3: color_pellets.dark.dark_theme_color_3,
    bg_color_4: color_pellets.dark.dark_theme_color_4,
    title_dark: color_pellets.dark.title,
    font_color_0: color_pellets.dark.dark_theme_color_13,
    font_color_1: color_pellets.dark.dark_theme_color_12,
    font_color_2: color_pellets.dark.dark_theme_color_11,
    font_color_3: color_pellets.dark.dark_theme_color_10,
    font_color_4: color_pellets.dark.dark_theme_color_9,
    button_color_0: color_pellets.dark.dark_theme_color_15,
    button_color_1: color_pellets.dark.dark_theme_color_14,
    button_color_2: color_pellets.dark.dark_theme_color_0,
    button_color_3: color_pellets.dark.dark_theme_color_1,
    button_color_4: color_pellets.dark.dark_theme_color_2,
    button_color_5: color_pellets.dark.dark_theme_color_3,
    button_color_7: color_pellets.dark.dark_theme_color_7,
    dark_theme_color_0: color_pellets.dark.dark_theme_color_0,
    dark_theme_color_1: color_pellets.dark.dark_theme_color_1,
    dark_theme_color_2: color_pellets.dark.dark_theme_color_2,
    dark_theme_color_3: color_pellets.dark.dark_theme_color_3,
    dark_theme_color_4: color_pellets.dark.dark_theme_color_4,
    dark_theme_color_5: color_pellets.dark.dark_theme_color_5,
    dark_theme_color_6: color_pellets.dark.dark_theme_color_6,
    dark_theme_color_7: color_pellets.dark.dark_theme_color_7,
    dark_theme_color_8: color_pellets.dark.dark_theme_color_8,
    dark_theme_color_9: color_pellets.dark.dark_theme_color_9,
    dark_theme_color_10: color_pellets.dark.dark_theme_color_10,
    dark_theme_color_11: color_pellets.dark.dark_theme_color_11,
    dark_theme_color_12: color_pellets.dark.dark_theme_color_12,
    dark_theme_color_13: color_pellets.dark.dark_theme_color_13,
    dark_theme_color_14: color_pellets.dark.dark_theme_color_14,
    dark_theme_color_15: color_pellets.dark.dark_theme_color_15,
    login: {
      primaryColor: color_pellets.dark.dark_theme_color_0,
      secondaryColor: color_pellets.dark.dark_theme_color_2,
      tertiaryColor: color_pellets.dark.dark_theme_color_13,
      quaternaryColor: `${color_pellets.dark.dark_theme_color_8} !important`,
      mainColor: color_pellets.dark.dark_theme_color_15,
      footerColor: color_pellets.dark.dark_theme_color_3,
      iconColor: color_pellets.dark.dark_theme_color_0,
      iconImg: "/assets/icons/msg_icon2.svg",
      bgImg: color_pellets.dark.bgImg,
      logoImg: color_pellets.dark.logo,
      RequestAssIcon: "/assets/images/request_assistance.svg",
      title: color_pellets.dark.title,
      favicon32x32: color_pellets.dark.favicon32x32,
      favicon16x16: color_pellets.dark.favicon16x16,
      favicon512x512: color_pellets.dark.favicon512x512,
      favicon180x180: color_pellets.dark.favicon180x180,
    },
    navbar: {
      primaryColor: color_pellets.dark.dark_theme_color_0,
      secondaryColor: color_pellets.dark.dark_theme_color_10,
      logoImg: color_pellets.dark.logo,
      infoIcon: "/assets/images/info_icon.svg",
    },
    spaces: {
      primaryColor: color_pellets.dark.dark_theme_color_1,
      secondaryColor: color_pellets.dark.dark_theme_color_13,
      tertiaryColor: color_pellets.dark.dark_theme_color_2,
      quaternaryColor: color_pellets.dark.dark_theme_color_1,
      bgColor: color_pellets.dark.dark_theme_color_1,
      mainColor: color_pellets.dark.dark_theme_color_10,
      emptySpace: "/assets/images/noevent.svg",
      dropdown: color_pellets.dark.dark_theme_color_0,
      border: `2px solid ${color_pellets.dark.dark_theme_color_2}`,
      sidebaricon: color_pellets.dark.dark_theme_color_1,
      myteambutton: color_pellets.dark.dark_theme_color_4,
    },
    loby: {
      primaryColor: color_pellets.dark.dark_theme_color_2,
      secondaryColor: color_pellets.dark.dark_theme_color_4,
      textColor: color_pellets.dark.dark_theme_color_13,
      bgColor: color_pellets.dark.dark_theme_color_1,
    },
    waiting: {
      primaryColor: color_pellets.dark.dark_theme_color_2,
      mainColor: color_pellets.dark.dark_theme_color_13,
      heading: color_pellets.dark.dark_theme_color_13,
      textColor: color_pellets.dark.dark_theme_color_13,
    },
    profile: {
      mainColor: color_pellets.dark.dark_theme_color_11,
      primaryColor: color_pellets.dark.dark_theme_color_12,
    },
    shareVideo: {
      primaryColor: color_pellets.dark.dark_theme_color_0,
      secondaryColor: color_pellets.dark.dark_theme_color_2,
      tertiaryColor: color_pellets.dark.dark_theme_color_2,
      quaternaryColor: color_pellets.dark.dark_theme_color_1,
      quinaryColor: color_pellets.dark.dark_theme_color_13,
      borderColor: color_pellets.dark.dark_theme_color_3,
      boxBorderColor: color_pellets.dark.dark_theme_color_4,
      cardBgcolor: color_pellets.dark.dark_theme_color_2,
      videoIconBgcolor: color_pellets.dark.dark_theme_color_1,
      lineColor: color_pellets.dark.dark_theme_color_3,
      cancelButtonColor: color_pellets.dark.dark_theme_color_13,
      cardTextColor: color_pellets.dark.dark_theme_color_10,
      dateTextColor: color_pellets.dark.dark_theme_color_6,
    },
    shareYoutube: {
      borderColor: color_pellets.dark.dark_theme_color_3,
    },
    table: {
      rowColor: color_pellets.dark.dark_theme_color_3,
      bgColor: color_pellets.dark.dark_theme_color_2,
      mainBg: color_pellets.dark.dark_theme_color_1,
      headerColor: color_pellets.dark.dark_theme_color_1,
      mainColor: color_pellets.dark.dark_theme_color_10,
      mainColor2: color_pellets.dark.dark_theme_color_15,
      buttonColor: color_pellets.dark.dark_theme_color_4,
      headingColor: color_pellets.dark.dark_theme_color_12,
      mainheadingColor: color_pellets.dark.dark_theme_color_13,
      buttonbgColor: color_pellets.dark.dark_theme_color_4,
      hoverColor: color_pellets.dark.dark_theme_color_3,
    },
    sidebar: {
      bgColor: color_pellets.dark.dark_theme_color_2,
      mainBg: color_pellets.dark.dark_theme_color_1,
      headerColor: color_pellets.dark.dark_theme_color_1,
      mainColor: color_pellets.dark.dark_theme_color_10,
      buttonColor: color_pellets.dark.dark_theme_color_4,
      headingColor: color_pellets.dark.dark_theme_color_12,
      mainheadingColor: color_pellets.dark.dark_theme_color_15,
      buttonbgColor: color_pellets.dark.dark_theme_color_4,
      sidebarColor: color_pellets.dark.dark_theme_color_2,
      sidebarText: color_pellets.dark.dark_theme_color_10,
    },
    settings: {
      bgColor: color_pellets.dark.dark_theme_color_3,
      containerbg: color_pellets.dark.dark_theme_color_0,
      buttonbgok: color_pellets.dark.dark_theme_color_15,
      textcolor: color_pellets.dark.dark_theme_color_10,
      setting: color_pellets.dark.dark_theme_color_12,
      borderBg: color_pellets.dark.dark_theme_color_2,
      fieldtext: color_pellets.dark.dark_theme_color_10,
    },
    team: {
      bgColor: color_pellets.dark.dark_theme_color_2,
      color: color_pellets.dark.dark_theme_color_12,
      participant: color_pellets.dark.dark_theme_color_3,
      border: `1px solid ${color_pellets.dark.dark_theme_color_4}`,
      divider: color_pellets.dark.dark_theme_color_3,
      headerBg: color_pellets.dark.dark_theme_color_1,
      hover: color_pellets.dark.dark_theme_color_4,
      hoverborder: `1px solid ${color_pellets.dark.dark_theme_color_3}`,
      textcolor: color_pellets.dark.dark_theme_color_10,
      viewtype: color_pellets.dark.dark_theme_color_4,
      viewtypetext: color_pellets.dark.dark_theme_color_10,
      checkboxborder: color_pellets.dark.dark_theme_color_4,
      checkboxhover: color_pellets.dark.dark_theme_color_3,
      dropdown: color_pellets.dark.dark_theme_color_1,
      dropdownBorder: color_pellets.dark.dark_theme_color_2,
      dropdownIcon: color_pellets.dark.dark_theme_color_2,
      folder: color_pellets.dark.dark_theme_color_2,
      default: color_pellets.dark.dark_theme_color_3,
      iconBg: color_pellets.dark.iconBg,
    },
    addSpace: {
      bgColor: color_pellets.dark.dark_theme_color_4,
      mainColor: color_pellets.dark.dark_theme_color_0,
      fontColor: color_pellets.dark.dark_theme_color_13,
      color1: color_pellets.dark.dark_theme_color_0,
      privatecolor: color_pellets.dark.dark_theme_color_13,
      textbgcolor: color_pellets.dark.dark_theme_color_3,
      breakoutrooms: color_pellets.dark.dark_theme_color_0,
      frontbg: color_pellets.dark.dark_theme_color_0,
      borderbackgroundcolor: color_pellets.dark.dark_theme_color_3,
      dividercolor: color_pellets.dark.dark_theme_color_3,
    },
    workflows: {
      bgColor: color_pellets.dark.dark_theme_color_2,
      color: color_pellets.dark.dark_theme_color_12,
      border: ` 1px solid ${color_pellets.dark.dark_theme_color_2}`,
      buttonBg: color_pellets.dark.dark_theme_color_3,
      textcolor: color_pellets.dark.dark_theme_color_10,
      headerBg: color_pellets.dark.dark_theme_color_1,
      hover: color_pellets.dark.dark_theme_color_4,
      icons: color_pellets.dark.dark_theme_color_10,
      workflowsAndpermissions: color_pellets.dark.dark_theme_color_10,
      checkboxBg: color_pellets.dark.dark_theme_color_1,
      workflowAndPermissionsBg: color_pellets.dark.dark_theme_color_3,
      iconsBg: color_pellets.dark.dark_theme_color_2,
      checkbox: color_pellets.dark.dark_theme_color_1,
      checkboxBorder: `1px solid ${color_pellets.dark.dark_theme_color_4}`,
      leftHeader: color_pellets.dark.dark_theme_color_2,
    },
    permissions: {
      color: color_pellets.dark.dark_theme_color_12,
      textcolor: color_pellets.dark.dark_theme_color_10,
      divider: color_pellets.dark.dark_theme_color_3,
      bgColor: color_pellets.dark.dark_theme_color_3,
      border: color_pellets.dark.dark_theme_color_4,
      videoquality: color_pellets.dark.dark_theme_color_0,
      hover: color_pellets.dark.dark_theme_color_4,
      bgColor1: color_pellets.dark.dark_theme_color_2,
      textColor: color_pellets.dark.dark_theme_color_12,
    },
    loading: {
      loadingColor: color_pellets.dark.dark_theme_color_3,
    },
    analytics: {
      bgColor: color_pellets.dark.dark_theme_color_2,
      textcolor: color_pellets.dark.dark_theme_color_13,
    },
    accounts: {
      bgColor: color_pellets.dark.dark_theme_color_2,
      textcolor: color_pellets.dark.dark_theme_color_13,
    },
    scrollbar: {
      scroll: color_pellets.dark.dark_theme_color_15,
      scrollbarBG: color_pellets.dark.dark_theme_color_0,
    },
    toggleButton: {
      button: color_pellets.dark.dark_theme_color_4,
    },
    common: {
      color1: color_pellets.dark.dark_theme_color_15,
      color2: color_pellets.dark.dark_theme_color_13,
      color3: color_pellets.dark.dark_theme_color_10,
      color4: color_pellets.dark.dark_theme_color_3,
      color5: color_pellets.dark.dark_theme_color_12,
      color6: color_pellets.dark.dark_theme_color_1,
      color7: color_pellets.dark.dark_theme_color_12,
    },
    addmember: {
      inboxcolor: color_pellets.dark.dark_theme_color_0,
      bordercolor: color_pellets.dark.dark_theme_color_2,
      backcolor: color_pellets.dark.dark_theme_color_11,
      frontcolor: color_pellets.dark.dark_theme_color_11,
      checkboxcolor: color_pellets.dark.dark_theme_color_2,
      lightbackground: color_pellets.dark.dark_theme_color_3,
      dropdawnbgcolor: color_pellets.dark.dark_theme_color_2,
      headerBg: color_pellets.dark.dark_theme_color_3,
      checkboxcolor1: color_pellets.dark.dark_theme_color_1,
      checkboxcolor2: color_pellets.dark.dark_theme_color_2,
      checkboxcolor3: color_pellets.dark.dark_theme_color_2,
      checkboxcolor4: color_pellets.dark.dark_theme_color_3,
      checkboxcolor5: color_pellets.dark.dark_theme_color_12,
      checkboxcolor7: color_pellets.dark.dark_theme_color_3,
      clockbg: color_pellets.dark.dark_theme_color_3,
      color1: color_pellets.dark.dark_theme_color_12,
      color2: color_pellets.dark.dark_theme_color_3,
      color3: color_pellets.dark.dark_theme_color_12,
      color4: color_pellets.dark.dark_theme_color_0,
    },
    teams: {
      backgroundcolor: color_pellets.dark.dark_theme_color_2,
    },
    filemanager: {
      backgroundcolor: color_pellets.dark.dark_theme_color_3,
      color1: color_pellets.dark.dark_theme_color_9,
    },
    selectimage: {
      backgroundcolor1: color_pellets.dark.dark_theme_color_2,
      backgroundcolor: color_pellets.dark.dark_theme_color_2,
      imagebackground: color_pellets.dark.dark_theme_color_3,
      dropdawnbackground: color_pellets.dark.dark_theme_color_1,
      bordercolor: color_pellets.dark.dark_theme_color_3,
    },
    selectvideo: {
      bordercolor: color_pellets.dark.dark_theme_color_2,
      textbackgroundcolor: color_pellets.dark.dark_theme_color_3,
      textcolor: color_pellets.dark.dark_theme_color_10,
      videoiconbgcolor: color_pellets.dark.dark_theme_color_1,
      videosizebgcolor: color_pellets.dark.dark_theme_color_2,
      videosizetextcolor: color_pellets.dark.dark_theme_color_6,
      videotextcolor: color_pellets.dark.dark_theme_color_10,
      selectbtbgcolor: color_pellets.dark.dark_theme_color_15,
    },
    spacescolor: {
      hoverbackground: color_pellets.dark.dark_theme_color_4,
      calenderbackground: color_pellets.dark.dark_theme_color_11,
      backgroundwhite: color_pellets.dark.dark_theme_color_1,
      bordercolor: color_pellets.dark.dark_theme_color_4,
      searchspacebordercolor: color_pellets.dark.dark_theme_color_3,
      color1: color_pellets.dark.dark_theme_color_3,
    },
    joinNowbutton: {
      bgcolorwhite: color_pellets.dark.dark_theme_color_3,
      bordercolor: color_pellets.dark.dark_theme_color_2,
      bgcolor1: color_pellets.dark.dark_theme_color_15,
      bgcolor2: color_pellets.dark.dark_theme_color_3,
      bgcolorwhitee: color_pellets.dark.dark_theme_color_13,
    },
    editspace: {
      outerbgcolor: color_pellets.dark.dark_theme_color_3,
      mainbgcolor: color_pellets.dark.dark_theme_color_0,
      bgcolor1: color_pellets.dark.dark_theme_color_2,
      dashedcolor: color_pellets.dark.dark_theme_color_2,
    },
    calender: {
      bgcolor1: color_pellets.dark.dark_theme_color_0,
      bgcolor2: color_pellets.dark.dark_theme_color_4,
      bgcolor3: color_pellets.dark.dark_theme_color_3,
      bordercolor: color_pellets.dark.dark_theme_color_2,
    },
    addspaceclock: {
      bgcolor: color_pellets.dark.dark_theme_color_2,
      maincolor: color_pellets.dark.dark_theme_color_12,
    },
    copylink: {
      bgcolor: color_pellets.dark.dark_theme_color_2,
    },
    avatar: {
      textcolor: color_pellets.dark.dark_theme_color_8,
    },
    darkfontcolor: {
      fontcolor: color_pellets.dark.dark_theme_color_11,
    },
    sharevideo: {
      youtubebgcolor: color_pellets.dark.dark_theme_color_2,
      urlbgcolor: color_pellets.dark.dark_theme_color_3,
      urltextcolor: color_pellets.dark.dark_theme_color_10,
      dividercolor: color_pellets.dark.dark_theme_color_2,
      buttoncolor: color_pellets.dark.dark_theme_color_3,
      filemanager1: color_pellets.dark.dark_theme_color_1,
      filemanager2: color_pellets.dark.dark_theme_color_2,
      filemanagertextcolor: color_pellets.dark.dark_theme_color_10,
      filemanagerheading: color_pellets.dark.dark_theme_color_10,
      canceltextcolor: color_pellets.dark.dark_theme_color_13,
      textbackground: color_pellets.dark.dark_theme_color_2,
    },
    searchiconcolor: {
      color: color_pellets.dark.dark_theme_color_10,
      crossmarkcolor: color_pellets.dark.dark_theme_color_8,
    },
    account: {
      bgColor: color_pellets.dark.dark_theme_color_2,
      text: color_pellets.dark.dark_theme_color_13,
      buttonBg: color_pellets.dark.dark_theme_color_3,
      buttonText: color_pellets.dark.dark_theme_color_10,
      normaltext: color_pellets.dark.dark_theme_color_10,
      border: `1px solid ${color_pellets.dark.dark_theme_color_8}`,
      cancelButton: color_pellets.dark.dark_theme_color_2,
      image: color_pellets.dark.dark_theme_color_3,
      upload: color_pellets.dark.dark_theme_color_7,
    },
  },
  light: {
    bg_color_0: color_pellets.light.light_theme_color_0,
    bg_color_1: color_pellets.light.light_theme_color_1,
    bg_color_2: color_pellets.light.light_theme_color_2,
    bg_color_3: color_pellets.light.light_theme_color_3,
    bg_color_4: color_pellets.light.light_theme_color_4,
    font_color_0: color_pellets.light.light_theme_color_13,
    font_color_1: color_pellets.light.light_theme_color_12,
    font_color_2: color_pellets.light.light_theme_color_11,
    font_color_3: color_pellets.light.light_theme_color_10,
    font_color_4: color_pellets.light.light_theme_color_9,
    button_color_0: color_pellets.light.light_theme_color_15,
    button_color_1: color_pellets.light.light_theme_color_14,
    button_color_2: color_pellets.light.light_theme_color_0,
    button_color_3: color_pellets.light.light_theme_color_1,
    button_color_4: color_pellets.light.light_theme_color_2,
    button_color_5: color_pellets.light.light_theme_color_3,
    button_color_7: color_pellets.light.light_theme_color_7,
    light_theme_color_0: color_pellets.light.light_theme_color_0,
    light_theme_color_1: color_pellets.light.light_theme_color_1,
    light_theme_color_2: color_pellets.light.light_theme_color_2,
    light_theme_color_3: color_pellets.light.light_theme_color_3,
    light_theme_color_4: color_pellets.light.light_theme_color_4,
    light_theme_color_5: color_pellets.light.light_theme_color_5,
    light_theme_color_6: color_pellets.light.light_theme_color_6,
    light_theme_color_7: color_pellets.light.light_theme_color_7,
    light_theme_color_8: color_pellets.light.light_theme_color_8,
    light_theme_color_9: color_pellets.light.light_theme_color_9,
    light_theme_color_10: color_pellets.light.light_theme_color_10,
    light_theme_color_11: color_pellets.light.light_theme_color_11,
    light_theme_color_12: color_pellets.light.light_theme_color_12,
    light_theme_color_13: color_pellets.light.light_theme_color_13,
    light_theme_color_14: color_pellets.light.light_theme_color_14,
    light_theme_color_15: color_pellets.light.light_theme_color_15,
    login: {
      primaryColor: color_pellets.light.light_theme_color_0,
      secondaryColor: color_pellets.light.light_theme_color_1,
      tertiaryColor: color_pellets.light.light_theme_color_13,
      quaternaryColor: color_pellets.light.light_theme_color_5,
      mainColor: color_pellets.light.light_theme_color_15,
      footerColor: color_pellets.light.light_theme_color_8,
      iconColor: color_pellets.light.light_theme_color_15,
      iconImg: "/assets/icons/msg_icon.svg",
      bgImg: color_pellets.light.bgImg,
      logoImg: color_pellets.light.logo,
      RequestAssIcon: "/assets/images/request_assistance.svg",
      title: color_pellets.light.title,
      favicon32x32: color_pellets.light.favicon32x32,
      favicon16x16: color_pellets.light.favicon16x16,
      favicon512x512: color_pellets.light.favicon512x512,
      favicon180x180: color_pellets.light.favicon180x180,
    },
    navbar: {
      primaryColor: color_pellets.light.light_theme_color_0,
      secondaryColor: color_pellets.light.light_theme_color_0,
      logoImg: color_pellets.light.logo,
      infoIcon: "/assets/images/info_icon.svg",
    },
    spaces: {
      primaryColor: color_pellets.light.light_theme_color_1,
      secondaryColor: color_pellets.light.light_theme_color_7,
      tertiaryColor: color_pellets.light.light_theme_color_0,
      quaternaryColor: color_pellets.light.light_theme_color_1,
      bgColor: color_pellets.light.light_theme_color_1,
      mainColor: color_pellets.light.light_theme_color_3,
      emptySpace: "/assets/images/noevent.svg",
      dropdown: color_pellets.light.light_theme_color_0,
      border: `1px solid ${color_pellets.light.light_theme_color_3}`,
      secondaryColor1: color_pellets.light.light_theme_color_3,
      sidebaricon: color_pellets.light.light_theme_color_0,
      myteambutton: color_pellets.light.light_theme_color_1,
      color: color_pellets.light.light_theme_color_0,
    },
    loby: {
      primaryColor: color_pellets.light.light_theme_color_2,
      secondaryColor: color_pellets.light.light_theme_color_9,
      textColor: color_pellets.light.light_theme_color_6,
      bgColor: color_pellets.light.light_theme_color_2,
    },
    waiting: {
      primaryColor: color_pellets.light.light_theme_color_1,
      mainColor: color_pellets.light.light_theme_color_0,
      heading: color_pellets.light.light_theme_color_7,
      textColor: color_pellets.light.light_theme_color_3,
    },
    profile: {
      mainColor: color_pellets.light.light_theme_color_2,
      primaryColor: color_pellets.light.light_theme_color_2,
    },
    shareVideo: {
      primaryColor: color_pellets.light.light_theme_color_0,
      secondaryColor: color_pellets.light.light_theme_color_1,
      tertiaryColor: color_pellets.light.light_theme_color_0,
      quaternaryColor: color_pellets.light.light_theme_color_2,
      quinaryColor: color_pellets.light.light_theme_color_7,
      borderColor: color_pellets.light.light_theme_color_1,
      boxBorderColor: color_pellets.light.light_theme_color_0,
      cardBgcolor: color_pellets.light.light_theme_color_1,
      videoIconBgcolor: color_pellets.light.light_theme_color_2,
      lineColor: color_pellets.light.light_theme_color_1,
      cancelButtonColor: color_pellets.light.light_theme_color_3,
      cardTextColor: color_pellets.light.light_theme_color_7,
      dateTextColor: color_pellets.light.light_theme_color_3,
    },
    shareYoutube: {
      borderColor: color_pellets.light.light_theme_color_2,
    },
    table: {
      rowColor: color_pellets.light.light_theme_color_0,
      bgColor: color_pellets.light.light_theme_color_1,
      mainBg: color_pellets.light.light_theme_color_2,
      headerColor: color_pellets.light.light_theme_color_7,
      mainColor: color_pellets.light.light_theme_color_3,
      mainColor2: color_pellets.light.light_theme_color_7,
      buttonColor: color_pellets.light.light_theme_color_1,
      headingColor: color_pellets.light.light_theme_color_3,
      mainheadingColor: color_pellets.light.light_theme_color_7,
      buttonbgColor: color_pellets.light.light_theme_color_0,
      hoverColor: color_pellets.light.light_theme_color_1,
    },
    sidebar: {
      rowColor: color_pellets.light.light_theme_color_0,
      bgColor: color_pellets.light.light_theme_color_1,
      mainBg: color_pellets.light.light_theme_color_2,
      headerColor: color_pellets.light.light_theme_color_7,
      mainColor: color_pellets.light.light_theme_color_3,
      buttonColor: color_pellets.light.light_theme_color_1,
      headingColor: color_pellets.light.light_theme_color_3,
      mainheadingColor: color_pellets.light.light_theme_color_7,
      buttonbgColor: color_pellets.light.light_theme_color_1,
      sidebarColor: color_pellets.light.light_theme_color_1,
      sidebarText: color_pellets.light.light_theme_color_7,
    },
    settings: {
      bgColor: color_pellets.light.light_theme_color_1,
      containerbg: color_pellets.light.light_theme_color_0,
      buttonbgok: color_pellets.light.light_theme_color_15,
      textcolor: color_pellets.light.light_theme_color_3,
      setting: color_pellets.light.light_theme_color_7,
      borderBg: color_pellets.light.light_theme_color_3,
      fieldtext: color_pellets.light.light_theme_color_10,
    },
    team: {
      bgColor: color_pellets.light.light_theme_color_1,
      color: color_pellets.light.light_theme_color_7,
      participant: color_pellets.light.light_theme_color_0,
      border: `1px solid ${color_pellets.light.light_theme_color_1}`,
      divider: color_pellets.light.light_theme_color_1,
      headerBg: color_pellets.lightlight_theme_color_7,
      hover: color_pellets.light.light_theme_color_1,
      hoverborder: `1px solid ${color_pellets.light.light_theme_color_4}`,
      textcolor: color_pellets.light.light_theme_color_3,
      viewtype: color_pellets.light.light_theme_color_1,
      viewtypetext: "black",
      checkboxborder: color_pellets.light.light_theme_color_2,
      checkboxhover: color_pellets.light.light_theme_color_4,
      dropdown: color_pellets.light.light_theme_color_0,
      dropdownBorder: `1px solid ${color_pellets.light.light_theme_color_1}`,
      dropdownIcon: color_pellets.light.light_theme_color_1,
      folder: color_pellets.light.light_theme_color_2,
      default: color_pellets.light.light_theme_color_1,
      iconBg: color_pellets.light.iconBg,
    },
    workflows: {
      bgColor: color_pellets.light.light_theme_color_1,
      color: color_pellets.light.light_theme_color_10,
      border: `1px solid ${color_pellets.light.light_theme_color_2}`,
      buttonBg: color_pellets.light.light_theme_color_0,
      textcolor: color_pellets.light.light_theme_color_6,
      headerBg: color_pellets.light.light_theme_color_7,
      hover: color_pellets.light.light_theme_color_1,
      icons: color_pellets.light.light_theme_color_3,
      workflowsAndpermissions: color_pellets.light.light_theme_color_0,
      checkboxBg: color_pellets.light.light_theme_color_7,
      workflowAndPermissionsBg: color_pellets.light.light_theme_color_7,
      iconsBg: color_pellets.light.light_theme_color_1,
      checkbox: color_pellets.light.light_theme_color_2,
      checkboxBorder: `1px solid ${color_pellets.light.light_theme_color_2}`,
      leftHeader: color_pellets.light.light_theme_color_1,
    },
    permissions: {
      color: color_pellets.light.light_theme_color_9,
      textcolor: color_pellets.light.light_theme_color_3,
      bgColor: color_pellets.light.light_theme_color_0,
      border: color_pellets.light.light_theme_color_1,
      divider: color_pellets.light.light_theme_color_1,
      videoquality: color_pellets.light.light_theme_color_3,
      hover: color_pellets.light.light_theme_color_1,
      bgColor1: color_pellets.light.light_theme_color_0,
      textColor: color_pellets.light.light_theme_color_3,
    },
    loading: {
      loadingColor: color_pellets.light.light_theme_color_2,
    },
    analytics: {
      bgColor: color_pellets.light.light_theme_color_1,
      textcolor: color_pellets.light.light_theme_color_3,
    },
    accounts: {
      bgColor: color_pellets.light.light_theme_color_1,
      textcolor: color_pellets.light.light_theme_color_3,
    },
    scrollbar: {
      scroll: color_pellets.light.light_theme_color_2,
      scrollbarBG: color_pellets.light.light_theme_color_2,
    },
    toggleButton: {
      button: color_pellets.light.light_theme_color_2,
    },
    common: {
      color1: color_pellets.light.light_theme_color_15,
      color2: color_pellets.light.light_theme_color_0,
      color3: color_pellets.light.light_theme_color_6,
      color4: color_pellets.light.light_theme_color_10,
      color5: color_pellets.light.light_theme_color_1,
      color6: color_pellets.light.light_theme_color_12,
      color7: color_pellets.light.light_theme_color_1,
    },
    addSpace: {
      bgColor: color_pellets.light.light_theme_color_1,
      mainColor: color_pellets.light.light_theme_color_0,
      addmore: color_pellets.light.light_theme_color_9,
      private: color_pellets.light.light_theme_color_9,
      color1: color_pellets.light.light_theme_color_0,
      textbgcolor: color_pellets.light.light_theme_color_1,
      breakoutrooms: color_pellets.light.light_theme_color_0,
      frontColor: color_pellets.light.light_theme_color_0,
      borderbackgroundcolor: color_pellets.light.light_theme_color_1,
      dividercolor: color_pellets.light.light_theme_color_2,
    },

    addmember: {
      inboxcolor: color_pellets.light.light_theme_color_0,
      bordercolor: color_pellets.light.light_theme_color_1,
      logobackgroundcolor: color_pellets.light.light_theme_color_9,
      backcolor: color_pellets.light.light_theme_color_2,
      frontcolor: color_pellets.light.light_theme_color_3,
      checkboxcolor: color_pellets.light.light_theme_color_11,
      lightbackground: color_pellets.light.light_theme_color_2,
      dropdawnbgcolor: color_pellets.light.light_theme_color_0,
      headerBg: color_pellets.light.light_theme_color_7,
      checkboxcolor1: color_pellets.light.light_theme_color_12,
      checkboxcolor2: color_pellets.light.light_theme_color_2,
      checkboxcolor3: color_pellets.light.light_theme_color_5,
      checkboxcolor4: color_pellets.light.light_theme_color_4,
      checkboxcolor5: color_pellets.light.light_theme_color_7,
      checkboxcolor6: color_pellets.light.light_theme_color_5,
      checkboxcolor7: color_pellets.light.light_theme_color_3,
      clockbg: color_pellets.light.light_theme_color_0,
      color1: color_pellets.light.light_theme_color_7,
      color2: color_pellets.light.light_theme_color_1,
      color3: color_pellets.light.light_theme_color_5,
      color4: color_pellets.light.light_theme_color_1,
    },
    teams: {
      backgroundcolor: color_pellets.light.light_theme_color_0,
    },
    filemanager: {
      backgroundcolor: color_pellets.light.light_theme_color_1,
      color1: color_pellets.light.light_theme_color_3,
    },
    selectimage: {
      backgroundcolor1: color_pellets.light.light_theme_color_1,
      backgroundcolor: color_pellets.light.light_theme_color_0,
      imagebackground: color_pellets.light.light_theme_color_2,
      dropdawnbackground: color_pellets.light.light_theme_color_0,
      bordercolor: color_pellets.light.light_theme_color_0,
      textcolor: color_pellets.light.light_theme_color_3,
    },
    selectvideo: {
      bordercolor: color_pellets.light.light_theme_color_1,
      textbackgroundcolor: color_pellets.light.light_theme_color_0,
      textcolor: color_pellets.light.light_theme_color_3,
      videoiconbgcolor: color_pellets.light.light_theme_color_2,
      videosizebgcolor: color_pellets.light.light_theme_color_1,
      videosizetextcolor: color_pellets.light.light_theme_color_3,
      videotextcolor: color_pellets.light.light_theme_color_7,
      selectbtbgcolor: color_pellets.light.light_theme_color_15,
    },
    spacescolor: {
      hoverbackground: color_pellets.light.light_theme_color_2,
      calenderbackground: color_pellets.light.light_theme_color_2,
      bordercolor: color_pellets.light.light_theme_color_3,
      backgroundwhite: color_pellets.light.light_theme_color_0,
      searchbordercolor: color_pellets.light.light_theme_color_1,
      color1: color_pellets.light.light_theme_color_1,
    },
    joinNowbutton: {
      bgcolorwhite: color_pellets.light.light_theme_color_0,
      bordercolor: color_pellets.light.light_theme_color_1,
      bgcolor1: color_pellets.light.light_theme_color_15,
      bgcolor2: color_pellets.light.light_theme_color_2,
      bgcolorwhitee: color_pellets.light.light_theme_color_0,
    },
    editspace: {
      outerbgcolor: color_pellets.light.light_theme_color_1,
      mainbgcolor: color_pellets.light.light_theme_color_0,
      bgcolor1: color_pellets.light.light_theme_color_1,
      dashedcolor: color_pellets.light.light_theme_color_2,
    },
    calender: {
      bgcolor1: color_pellets.light.light_theme_color_0,
      bgcolor2: color_pellets.light.light_theme_color_1,
      bgcolor3: color_pellets.light.light_theme_color_0,
      bordercolor: color_pellets.light.light_theme_color_1,
    },
    addspaceclock: {
      bgcolor: color_pellets.light.light_theme_color_0,
      maincolor: color_pellets.light.light_theme_color_1,
    },
    copylink: {
      bgcolor: color_pellets.light.light_theme_color_0,
    },
    avatar: {
      textcolor: color_pellets.light.light_theme_color_2,
    },
    darkfontcolor: {
      fontcolor: color_pellets.light.light_theme_color_2,
      fontcolor1: color_pellets.light.light_theme_color_1,
    },
    sharevideo: {
      youtubebgcolor: color_pellets.light.light_theme_color_1,
      urlbgcolor: color_pellets.light.light_theme_color_1,
      urltextcolor: color_pellets.light.light_theme_color_2,
      dividercolor: color_pellets.light.light_theme_color_2,
      buttoncolor: color_pellets.light.light_theme_color_0,
      filemanager1: color_pellets.light.light_theme_color_2,
      filemanager2: color_pellets.light.light_theme_color_1,
      filemanagertextcolor: color_pellets.light.light_theme_color_3,
      filemanagerheading: color_pellets.light.light_theme_color_7,
      canceltextcolor: color_pellets.light.light_theme_color_3,
      textbackground: color_pellets.light.light_theme_color_0,
    },
    searchiconcolor: {
      color: color_pellets.light.light_theme_color_3,
      crossmarkcolor: color_pellets.light.light_theme_color_8,
    },
    account: {
      bgColor: color_pellets.light.light_theme_color_1,
      text: color_pellets.light.light_theme_color_7,
      buttonBg: color_pellets.light.light_theme_color_7,
      buttonText: color_pellets.light.light_theme_color_0,
      normaltext: color_pellets.light.light_theme_color_5,
      border: `2px solid ${color_pellets.light.light_theme_color_5}`,
      cancelButton: color_pellets.light.light_theme_color_0,
      image: color_pellets.light.light_theme_color_1,
      upload: color_pellets.light.light_theme_color_2,
    },
  },
};

export const ThemeSlice = createSlice({
  name: "Theme",
  initialState: {
    theme: theme,
    themeData: {
      ...styles[theme],
    },
    eventTheme: {
      ...styles,
    },
  },
  reducers: {
    //update light theme
    setEventLight: (state, action) => {
      state.eventTheme.light = {
        ...state.eventTheme.light,
        ...action.payload,
      };
    },
    //update dark theme
    setEventDark: (state, action) => {
      state.eventTheme.dark = {
        ...state.eventTheme.dark,
        ...action.payload,
      };
    },
    setThemeSlice: (state, action) => {
      state.theme = action.payload;
      state.themeData = {
        ...styles[action.payload],
      };
      localStorage.setObject("theme", action.payload);
    },
    // set event theme data to initial
    setEventThemeInitial: (state) => {
      state.eventTheme = {
        ...styles,
      };
    },
    updateThemeData: (state, action) => {
      console.log(action.payload, "action.payload");
      console.log(action.payload.type, action.payload, "action.payload.type");
      if (action.payload.type === "dark_theme") {
        state.themeData.dark = {
          ...state.themeData.dark,
          ...action.payload,
        };
      } else {
        state.themeData.light = {
          ...state.themeData.light,
          ...action.payload,
        };
      }
      // state.themeData = {
      //   ...state.themeData,
      //   ...action.payload,
      // };
    },
    setThemeInitial: (state, action) => {
      console.log(action.payload, "action.payload");
      state.theme = action.payload;
      state.themeData = {
        ...styles[action.payload],
      };
      localStorage.setObject("theme", action.payload);
    },
  },
});

export const {
  setThemeSlice,
  updateThemeData,
  setThemeInitial,
  setEventLight,
  setEventDark,
  setEventThemeInitial,
} = ThemeSlice.actions;

export default ThemeSlice.reducer;
