export const WEBSITE_DOMAIN = window.location.origin + "/";

function uppercaseWord(word) {
  return word.toUpperCase();
}



export const WEBSITE_PATH = window.location.hostname;

export const CAPITALIZE_WEBSITE_PATH = uppercaseWord(window.location.hostname);